import React, { useState } from "react";
import "./Login.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import Google from "../../ApiTesting/GoogleLogin";
import ReCAPTCHA from "react-google-recaptcha";
import PublicLayout from "../../component/PublicLayout";
import loader from "../../static/loader.gif";

function Login() {
  const [isVerified, setIsVerified] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  function onChange() {
    setIsVerified(!isVerified);
  }

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(!loading);
    try {
      const response = await axios.post(
        "https://api.certifieddump.com/api/login",
        formData
      );
      sessionStorage.setItem("token", response.data.token);
      sessionStorage.setItem("userId", response.data.user.id);
      sessionStorage.setItem("currency", response.data.user.currency);
      sessionStorage.setItem("username", response.data.user.username);
      sessionStorage.setItem("userFirstname", response.data.user.firstname);
      sessionStorage.setItem("userLastname", response.data.user.lastname);
      sessionStorage.setItem("userEmail", response.data.user.email);
      sessionStorage.setItem("userPhone", response.data.user.phone_no);
      sessionStorage.setItem("country", response.data.user.country);
      sessionStorage.setItem("dob", response.data.user.dob);
      sessionStorage.setItem("gender", response.data.user.gender);
      sessionStorage.setItem("userImg", response.data.user.image_path);
      toast.success(response.data.msg);
      if (sessionStorage.getItem("bookRoute")) {
        navigate(sessionStorage.getItem("bookRoute"));
        sessionStorage.removeItem("bookRoute");
      } else navigate("/dashboard");
    } catch (error) {
      toast.error(error.response.data.msg);
      if (error.response.data.msg == "Unverified Email ") {
        navigate("/verify-email");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PublicLayout>
      <div className="login container">
        <div className="login-form-wrap">
          <Form
            className="login-form"
            onSubmit={(e) => {
              e.preventDefault();
              isVerified ? handleSubmit() : setShow(!show);
            }}
          >
            <Form.Group className="mb-3 ">
              <Form.Label className="my-4 form-title"> Log in</Form.Label>
            </Form.Group>
            <Form.Group className="mb-3 form-group-s">
              <Form.Control
                className="form-control-s"
                type="text"
                placeholder="Enter your username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                autoComplete="true"
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3 form-group-s"
              style={{ position: "relative" }}
            >
              <Form.Control
                className="form-control-s"
                type={viewPassword ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                autoComplete="true"
                required
              />
              <span
                className="password-toggle-icon"
                onClick={() => setViewPassword(!viewPassword)}
              >
                {viewPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </span>
            </Form.Group>
            <div className="login-capcha">
              <ReCAPTCHA
                sitekey="6LeublgoAAAAAJcgjbrXDIOqr3839Npd0g-sE8Pd"
                onChange={onChange}
              />
            </div>
            {show && (
              <small
                style={{
                  color: "red",
                  fontSize: "14px",
                }}
              >
                Please solve recapcha puzzle
              </small>
            )}

            <Button type="submit" className="form-button-s">
              {loading ? (
                <img loading="lazy" src={loader} alt="loading gif" />
              ) : (
                <>SIGN IN</>
              )}
            </Button>
            <Link
              to="/forgot-password"
              style={{ color: "var(--green-600", fontWeight: "bolder" }}
            >
              forgot password?
            </Link>
            <p
              style={{
                fontWeight: "bolder",
                color: "black",
                fontSize: "12px",
              }}
            >
              Don&apos;t have an account yet?
              <Link
                to="/signup"
                style={{ color: "var(--green-600", fontWeight: "light" }}
              >
                Sign up
              </Link>
            </p>
            <p
              style={{
                fontWeight: "bolder",
                color: "black",
                fontSize: "12px",
              }}
            >
              Or log in with Google
            </p>
            <Google />
          </Form>
        </div>
      </div>
    </PublicLayout>
  );
}

export default Login;
