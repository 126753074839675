import axios from "axios";
import React from "react";
import toast from "react-hot-toast";

const RowWallet = ({ each, formatBal }) => {
  const status = each.status.toLowerCase();
  const currency = sessionStorage.getItem("currency");

  const VerifyPayment = async (ref) => {
    try {
      const response = await axios.get(
        `https://api.certifieddump.com/api-wallet/verifypayment?ref=${ref}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if(response.data?.msg){
        toast.success(response.data?.msg)
        
        setTimeout(()=>{
          response.data?.msg&&window.location.reload()
        },1500)
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg||"Something went wrong please try again")
   
      setTimeout(()=>{
        error?.response?.data?.msg && window.location.reload()
      },1500)
    }
  };
  return (
    <>
      <td>
        <p>{each.type}</p>
        <em>
          {new Date(each.date).toLocaleDateString() +
            " " +
            new Date(each.date).toLocaleTimeString()}
        </em>
      </td>
      <td>
        <p>
          {currency === "NGN" ? (
            <s style={{ textDecorationStyle: "double" }}>N</s>
          ) : (
            "$"
          )}
          {formatBal(each.amount)}
        </p>
        {status === "successful" ? (
          <em className="d-mobile-success">Successful</em>
        ) : status === "pending" ? (
          <em onClick={()=>VerifyPayment(each.trx_id)} className="d-mobile-pend">Pending</em>
        ) : (
          <em className="d-mobile-fail">Failed</em>
        )}
      </td>
    </>
  );
};

export default RowWallet;
