import axios from "axios";
import React from "react";
import toast from "react-hot-toast";

const DesktopRow = ({ each, i, formatBal }) => {
  const status = each.status.toLowerCase();
  const VerifyPayment = async (ref) => {
    try {
      const response = await axios.get(
        `https://api.certifieddump.com/api-wallet/verifypayment?ref=${ref}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if(response.data?.msg){
        toast.success(response.data?.msg)
        
        setTimeout(()=>{
          response.data?.msg&&window.location.reload()
        },1500)
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg||"Something went wrong please try again")
   
      setTimeout(()=>{
        error?.response?.data?.msg && window.location.reload()
      },1500)
    }
  };
  return (
    <tr key={i} className="d-desktop-row">
      <td>{i + 1}</td>
      <td>{each.trx_id}</td>
      <td>{formatBal(each.amount)}</td>
      <td>{new Date(each.date).toLocaleDateString() +
          " " +
          new Date(each.date).toLocaleTimeString()}
      </td>
      <td>{each.type}</td>
      {status === "successful" ? (
        <td className="d-status-success">Successful</td>
      ) : status === "pending" ? (
        <td className="d-status-pend">Pending <span  onClick={()=>VerifyPayment(each.trx_id)} className="btn bg-warning ">check status</span></td>
        
      ) : (
        <td className="d-status-fail">Failed</td>
      )}
    </tr>
  );
};

export default DesktopRow;
