export const navlist = [
  {
    title: "HOME",
    path: "/",
  },
  {
    title: "ABOUT US",
    path: "/about",
  },
  {
    title: "PROVIDERS",
    path: "/providers",
  },
  {
    title: "BLOG AREA",
    path: "/blog",
  },
  {
    title: "CONTACT US",
    path: "/contact",
  },
];
